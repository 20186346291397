.product-container {
  margin: 3vh auto;
  max-width: 1200px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 3rem;
  font-size: 1.3rem;
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.product-item-img {
  height: auto;
  max-width: 100%;
  border-radius: 8px;
}

.product-item-desc {
  width: 100%;
}

.product-item-title {
  width: 100%;
  font-weight: bold;
}

.product-item-price {
  color: blue;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
}

.product-detail {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

@media (min-width: 768px) {
  .product-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    font-size: 1.5rem;
  }

  .product-item {
    width: calc(33.333% - 2rem);
    margin-bottom: 2rem; 
  }
}

@media (max-width: 768px) and (min-width: 480px) {
  .product-item {
    width: calc(50% - 2rem);
    margin-bottom: 2rem; 
  }
}

@media (max-width: 480px) {
  .product-item {
    width: calc(100% - 2rem);
    margin-bottom: 1.5rem;
    font-size: 1.2rem; 
  }
}
