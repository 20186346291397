.home-slide {
  padding: 1rem;
  width: 80vw;
  max-width: 1200px;
  margin: 4rem auto;
  font-size: 1.3rem;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  color: #333;
}

.home-slide ul {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.home-slide ul li {
  border: 2px solid #b8b7b7;
  border-radius: 50%;
  padding: 10px;
}

.home-slide ul li button {
  background: transparent !important;
  color: transparent !important;
  transition: color 0.5s ease;
}

.home-slide ul li button:hover::before {
  color: #0f3460;
}

.home-slide ul li button::before {
  font-size: 15px;
  color: #0f3460;
  position: absolute;
  top: 2px;
  left: 0;
  margin: auto;
}

.slide-card {
  display: flex !important;
  gap: 2rem;
  transition: transform 0.5s ease;
}

.slide-card:hover {
  transform: scale(1.02);
}

.left-slide-side {
  width: 100%;
  height: 60vh;
}

.left-slide-side img {
  width: 100%;
  height: 60%;
  border-radius: 10px;
}

.right-slide-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.right-slide-side p {
  margin: 1rem 0;
  line-height: 1.5;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.pagination-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
}

.item {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  justify-content: center;
  width: auto;
}

.disabled-page {
  color: #808e9b;
}

.active {
  border-bottom: solid 1px #808e9b;
  color: #232324;
}

.next,
.previous {
  font-size: small;
  height: auto;
}

.next {
  right: 30px;
}

.previous {
  left: 30px;
}

.pagination {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

/* Media Queries for responsiveness */

@media (max-width: 1024px) {
  .home-slide {
    width: 90vw;
    margin-top: 4rem;
    font-size: 1rem;
    padding-top: 0;
    box-shadow: none;
    border-radius: 5px;
  }

  .slide-card {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: calc(95vw - 5rem);
    margin: 3rem 0;
    height: 80vh;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) -1px -1px 5px;
  }

  .left-slide-side img,
  .right-slide-side {
    width: 100%;
    height: 40vh;
  }

  .pagination {
    width: auto;
    height: auto;
  }
}

@media (max-width: 768px) {
  .home-slide {
    font-size: 0.7rem;
    height: auto;
    width: 90vw;
    margin-top: 6rem;
    padding-top: 0;
  }

  .slide-card {
    gap: 0;
    padding-top: 0;
  }

  .left-slide-side {
    height: 40vh;
    width: 100%;
  }

  .right-slide-side p {
    height: auto;
    width: 100%;
    margin: 0.3rem 0;
    line-height: 1.3;
  }

  .item {
    font-size: 1rem;
    height: auto;
    width: auto;
  }

  .next,
  .previous {
    font-size: x-small;
    height: auto;
  }
}
