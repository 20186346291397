.search-box {
  position: absolute;
  left: 50%;
  top: 13%;
  width: 70%;
  transform: translateX(-50%);
  max-width: 600px;
  height: auto;
  z-index: 100;
}

.mobile-search-box {
  position: absolute;
  left: 80%;
  top: 20%;
  width: 10vw;
  height: 5vh;
  background-color: #727272;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 60%;
}

.mobile-search-box {
  position: absolute;
  top: 22%;
}

.modal-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(15px);
  z-index: 100;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.modal-content input {
  position: absolute;
  top: 5%;
  width: 100%;
  height: 5%;
  padding: 1rem;
  font-size: 1rem;
  outline: none;
}

.modal-content button {
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 5%;
  border: none;
  outline: none;
  background-color: #fff;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.search-results {
  width: 100%;
  height: 80%;
}

.search-results .result-product {
  width: 100%;
  height: 10%;
  background-color: transparent;
  font-size: 1rem;
}

.search-results .result-product img {
  width: 20%;
  height: 50%;
}

.search-input {
  width: 100%;
  height: 5vh;
  background-color: transparent;
  color: #fff;
  font-size: 2rem;
  display: flex;
}

.search-input svg {
  background-color: #727272;
  height: 100%;
  width: 40px;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.search-input input {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  outline: none;
  border: none;
  background-color: #727272;
  color: #fff;
  height: 100%;
  width: calc(100% - 50px);
  padding: 0.5rem;
  font-size: 1.2rem;
}

.search-input input::placeholder {
  color: #b1b1b1;
}

.result-box-open {
  background-color: #fff;
  margin-top: 0.5rem;
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
  width: calc(100% - 30px);
  min-height: 2rem;
  height: fit-content;
  z-index: 100;
  padding: 0.5rem;
  font-size: 1rem;
}

.result-box-close {
  display: none;
}

.result-product {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  height: 10vh;
  width: 100%;
  gap: 2rem;
  margin-top: 0.5rem;
  border-bottom: 1px solid #f5f3f3;
}

.result-product-image {
  width: 15%;
  height: 100%;
}

.result-product-btn {
  border: none;
  outline: none;
  font-size: 1.2rem;
  width: 100%;
  background-color: #fff;
  height: auto;
  cursor: pointer;
}

/* Media Queries */
@media (max-width: 768px) {
  .search-box {
    width: 30%;
    top: 19%;
    left: 80%;
  }

  .search-input {
    font-size: 1.5rem;
  }

  .search-input input {
    font-size: 1.2rem;
  }

  .result-box-open {
    width: 100%;
  }

  .result-product {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    height: 15vh;
  }

  .result-product-image {
    width: 30%;
  }
  .result-product-btn {
    height: 5vh;
  }
}

