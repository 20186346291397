.dashboard {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem 1.5rem;
  font-weight: 600;
}

.dashboard button {
  width: 10vw;
  height: auto;
}

.product-basket {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem;
  font-size: 1.2rem;
}

.basket-image {
  width: 8vw;
  height: auto;
}

.cart {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  height: auto;
  box-shadow: rgba(43, 52, 69, 0.1) 0px 4px 16px;
  padding: 1rem;
}

.cart a {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  width: 100%;
}

.right-basket {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: absolute;
  right: 5%;
}

.basket-title {
  width: auto;
  padding-top: 1.5rem;
}

@media (max-width: 768px) {
  .dashboard button {
    width: auto;
  }
  .product-basket {
    font-size: 1rem;
  }
  .basket-image {
    width: 20vw;
  }
  .cart {
    flex-direction: column;
  }
  .right-basket {
    position: relative;
    right: auto;
    gap: .3rem;
  }
}
