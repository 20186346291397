.category {
  font-size: 1.5rem;
  width: 7vw;
  height: 5vh;
  position: absolute;
  left: 4%;
  top: 15%;
  background-color: #cecececb;
  padding: 0.4rem 0.7rem;
  border-radius: 1rem;
}

.select {
  cursor: pointer;
}

.category-box {
  width: 15vw;
  height: fit-content;
  color: black;
  position: relative;
  left: 10%;
  margin-top: 1rem;
  background-color: #fff;
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
  z-index: 1;
  padding: 0.3rem;
}

.category-box a p {
  transition: 0.5s;
}

.category-box a p:hover {
  color: #fdc5ce;
}

.close {
  display: none;
}

.open {
  display: block;
}

.category-box .categories {
  display: block;
  margin: 1rem;
  cursor: pointer;
  width: 100%;
}

@media (max-width: 768px) {
  .category {
    font-size: 1rem;
    width: 15vw;
    height: 4vh;
    top: 20%;
    padding: 0 0.8rem;
    border-radius: 0.5rem;
  }

  .category-box {
    width: 25vw;
    padding: 0.2rem;
    margin-top: .5rem;
  }
}

@media (max-width: 425px) {
  .category {
    width: 25vw;
    top: 23%;
    font-size: 0.7rem;
  }

  .category-box {
    width: 33vw;
    margin-top: .5rem;
    padding: 0.2rem;
  }

  .categories {
    width: 80%;
  }
}
