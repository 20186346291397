.navbar {
  background-color: #0f3460;
  padding: 10px 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
  width: 100vw;
  position: relative;
  top: 0;
  z-index: 10;
}

.right-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin-right: 2rem;
  gap: 2.5rem;
}

.theme-changer,
.login-icon a {
  cursor: pointer;
  transition: color 0.5s;
  color: #fff;
}

.theme-changer:hover,
.login-icon a:hover {
  color: #ffdd57;
}

.left-navbar {
  margin-left: 3rem;
}

.navigation {
  font-size: 1.5rem;
  margin: auto;
  width: auto;
}

.navigation ul {
  list-style: none;
  display: flex;
  margin-bottom: 2rem;
  gap: 2rem;
  padding: 0;
}

.navigation ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

@media (max-width: 768px) {
  .navbar {
    height: 20%;
    padding: 10px;
    width: 100%;
    overflow-x: hidden;
    font-size: .8rem;
    justify-content: space-evenly;
    align-items: center;
  }

  .right-navbar {
    flex-direction: column;
    margin-right: 20px;
    margin-top: 10px;
    gap: 1.5rem;
  }

  .navigation {
    width: 100%;
    font-size: 1.2rem;
    height: auto;
    margin-top: 10px;
  }

  .navigation ul {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding-left: 0;
  }
}
