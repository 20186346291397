footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  height: 15vh;
  background-color: #0f3460;
  padding: 1rem;
}

.social-media {
  color: white;
  position: relative;
  left: 70%;
  top: 0;
  display: flex;
  gap: 1em;
}

.social-media svg {
  cursor: pointer;
}

.left-footer {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  position: relative;
  left: 0;
  top: 0;
}

.left-footer p {
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  footer {
    height: 20%;
    padding: 0.5rem;
  }

  .social-media {
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    left: 80%;
  }

  .left-footer {
    left: 0;
    gap: 0.5rem;
  }

  .left-footer p {
    font-size: 0.7rem;
  }
}
