.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin: 2rem;
}

.product-list a {
  text-decoration: none;
}

.product-card {
  background-color: white;
  height: auto; 
  width: 20vw; 
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.5rem;
  padding: 1em; 
  color: black;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}

.product-card:hover {
  transform: translateY(-5px); 
  box-shadow: rgb(43 52 69 / 20%) 0px 8px 24px; 
}

.product-image {
  height: auto; 
  width: 100%; 
  max-height: 30vh; 
  border-radius: 1em;
}

.product-price {
  color: blue;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 2rem;
}

.product-rating {
  margin-top: .8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .product-card {
    width: calc(40% - 2rem); 
    font-size: 1.3rem; 
  }
}

@media (max-width: 768px) {
  .product-card {
    width: calc(100% - 2rem);
    font-size: 1.2rem; 
    height: auto; 
    padding: 0.5em; 
  }

  .product-image {
    max-height: 25vh; 
  }
}
